<span *ngIf="!isEditing() && !isNewClient()">
	<app-client-profile 
        [client]="newClient" 
        [timeframes]="timeframes"
        [selectedTimeframe]="newClient.timeframe"
        [title]="getTitle()"
        (buttonAction)="toggleEdit()" tabindex="0">
    </app-client-profile>
</span>
<div class="mb-4 content-container" *ngIf="isEditing() || isNewClient()">
    <div class="title">            
            <h1><span tabindex="0">{{ getTitle() }}</span></h1>
        </div>
    
    <div class="clientForm">
        <div class="row">
            <div class="client-picture-wrapper" (click)="fileInput.click()">
                <div class="imagePlacing" align="center">                  
                  <img class="image" [src]="isValidImageUrl(newClient.picture) ? newClient.picture : './assets/default-profile-pic.png'" alt="Client Picture" tabindex="0" />
                  <mat-label tabindex="0" class="upload-text">Upload Profile <br>Photo</mat-label>
                  <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput
                     tabindex="0" id="uploadPicture" aria-labelledby="uploadPicture" accept=".png, .jpg, .jpeg">
                </div>
            </div>
            <div class="form-field-wrapper personal-info-section">
                <div class="row">
                <div class="col-md-6 tab-width personal-info-section">
                    <h2>Personal Info</h2>
                    <div class="mobile-personalText">
                    <div class="row">
                        <div class="col-md-6">
                        <div class="form-group" align="left">
                            <label tabindex="0">First Name <span style="color: red">*</span></label>
                            <input type="text" [formControl]="firstNameControl" [(ngModel)]="newClient.firstName" name="firstName"
                               value="" (focus)="formStartEvent()"
                                class="inputLine form-control" style="background:transparent ;" tabindex="0" id="fname" aria-labelledby="fname" FirstName autocomplete="off">
                                <div class="error-message" *ngIf="firstNameControl.invalid">First name with no symbols, digits and space is required</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" align="left">
                            <label tabindex="0">Last Name <span style="color: red">*</span></label>
                            <input type="text" [formControl]="lastNameControl" [(ngModel)]="newClient.lastName" name="lastName" 
                                class="inputLine form-control"
                                style="background:transparent ;" tabindex="0" id="lname" aria-labelledby="lname" LastName autocomplete="off"/>
                                <div class="error-message" *ngIf="lastNameControl.invalid">Last name with no symbols, digits and space is required</div>
                        </div>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                        <div class="form-group" align="left">
                            <label tabindex="0">Phone <span style="color: red">*</span></label>
                            <input type="tel" [formControl]="phoneControl" [(ngModel)]="newClient.phone" name="phone"                      value="" (keydown.Tab)="onKey1($event);"
                                class="inputLine form-control" tabindex="0" style="background:transparent ;" id="phone" aria-labelledby="phone" autocomplete="off" NumberOnly/>
                                <div class="error-message" *ngIf="phoneControl.invalid">Valid Phone number is required</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label #Email tabindex="0">Email <span style="color: red">*</span></label>
                            <input type="text" [formControl]="emailControl" [(ngModel)]="newClient.email" name="email"
                                value=""
                                class="inputLine form-control" tabindex="0" style="background:transparent ;" id="email" aria-labelledby="email" autocomplete="off"/>
                                <div class="error-message" *ngIf="emailControl.invalid">Valid email is required</div>
                        </div>
                    </div>
                </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label tabindex="0">Address</label>
                            <div class="form-group">
                        <app-address-autocomplete *ngIf="newClient.address != undefined"
                            [(address)]="newClient.address[0].fullAddress"
                            (cityChange)="newClient.address[0].city = $event"
                            (zipCodeChange)="newClient.address[0].zip = $event"
                            (stateChange)="newClient.address[0].state = $event">
                        </app-address-autocomplete>
                    </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                                <label tabindex="0">City</label>
                                <input type="text" *ngIf="newClient.address != undefined" [(ngModel)]="newClient.address[0].city" class=" inputLine form-control" tabindex="0"
                                    style="background:transparent ;" id="city" aria-labelledby="city" autocomplete="off" City/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label tabindex="0">State</label>
                               <input type="text" *ngIf="newClient.address != undefined" [(ngModel)]="newClient.address[0].state"  class=" inputLine form-control" id="State" aria-labelledby="State"
                                   style="background:transparent ;" tabindex="0" autocomplete="off" state />
                        </div>
                        </div>
                    <div class="col-md-6">
                            <label tabindex="0">ZIP Code</label>
                            <input type="text" *ngIf="newClient.address != undefined" [(ngModel)]="newClient.address[0].zip" class="inputLine form-control" tabindex="0"
                                style="background:transparent ;" id="zipcode" aria-labelledby="zipcode" autocomplete="off" ZipCode (keydown.Tab)="onKey2($event);"/>
                        </div>
                    </div>
                    </div>
                    <div class="row">
                    <mat-label>
                        <div class="section-header mt-3">
                        <div class="title">                               
                            <h2><span #Timeframe tabindex="0">Timeframe <span style="color: red">*</span></span></h2>
                        </div>      
                    </div>                       
                    </mat-label>
                    <mat-button-toggle-group  [formControl]="timeframeControl" #group name="clientTimeframe" aria-label="Client-Timeframe" class="btn"
                        [value]="newClient.timeframe" (change)="onTimeframeSelection($event)">
                        <mat-button-toggle *ngFor="let timeframe of timeframes" [value]="timeframe.id"
                            class="btn timeframe-option"
                            [ngClass]="{'selected': newClient.timeframe === timeframe.id}" tabindex="0">
                            {{timeframe.name}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <div class="error-message" *ngIf="timeframeControl.invalid">Please select a timeframe</div>
                </div>
                </div>
                <div class="col-md-6 custom-padding-left tab-width">
                    
                <div class="row">
                    <div class="col-md-12 px-md-5 tabGap">
                        <div class="title">                               
                            <h2><span tabindex="0">Lifestyle</span></h2>
                        </div> 
                        <div class="lifestyle-wrapper">
                            <div class="list-wrapper">
                                <mat-accordion class="headers-align">
                                    <mat-expansion-panel  [expanded]="step === i" (opened)="setStep(i,true)" (closed)="setStep(i,false)" hideToggle class="section" *ngFor="let category of poiCategory;let i = index">
                                    <mat-expansion-panel-header class="list-section"  [ngClass]="{'active-category': isCategoryHighlighted(category) }">
                                        <span>{{category.name}}</span>
                                        <!-- <span class="material-icons">{{category['expanded']?'indeterminate_check_box':'add_box'}}</span> -->
                                        <span class="">{{category['expanded']?'-':'+'}}</span>
                                         <!-- <mat-checkbox class=""
                                            [checked]="category.checked"
                                            [color]="'Accent'"
                                            [indeterminate]="someComplete(i)"
                                            (change)="setAll($event.checked, category, i)">
                                            </mat-checkbox> -->
                                    </mat-expansion-panel-header>
                                    <span class="list-section">
                                        <ul class="padding-left">
                                        <li *ngFor="let subtask of category.subcategory">
                                            <mat-checkbox [(ngModel)]="subtask.checked"
                                                        (change)="setAllSubCategory($event.checked,category,subtask,i)"
                                                        [color]="subtask.color?subtask.color:Primary"
                                                        (ngModelChange)="updateAllComplete(i)">
                                            {{subtask.name}}
                                            </mat-checkbox>
                                        </li>
                                        </ul>
                                    </span>
                                </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <div class="error-message" *ngIf="categoryControl.invalid">Please select a category</div>
                        </div>
                    </div>
                </div>
                </div>                
           
            <div class="left-align" *ngIf="error != null">{{error}}</div>
    <div class="row">
        <span *ngIf="isEditing() || isNewClient()">
            <div class="mt-4 button-group">
                <button type="submit" class="big-ass-button" (click)="onConfirmCreation()" tabindex="0">
                    {{ getActionButtonTitle() }}
                </button>
         
                <button type="button" class="cancel-button" (click)="onCancelCreation()" tabindex="0">
                    CANCEL
                </button>
            </div>
        </span>

        <span *ngIf="!isEditing() && !isNewClient()">
            <div class="col-md-6">
                <button type="submit" class="btn formEdit" (click)="toggleEdit()" tabindex="0">
                    Edit
                </button>
            </div>
        </span>
    </div>
    <div class="row">
        <!-- <div class="row">
                    <div class="col-md-12 mt-4">
                        <div class="title">                               
                            <h2><span tabindex="0">Touring Properties</span> <i class="bi bi-plus-square plusicon heading-icon"></i></h2>
                        </div> 
                        <div class="touring-address mt-1">
                            <img src="assets/locate-icon.svg" alt="Address Icon" width="40px" tabindex="0"> <h3>Lakeside Villa, Los Angeles, CA.</h3>
                        </div>
                    </div>
                </div> -->
        <app-spinner [show]="showSpinner"></app-spinner>
        <app-checkmark *ngIf="showCheck"></app-checkmark>
    </div>
</div>
</div>
</div>

</div>

