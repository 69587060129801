import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';

import { LoginService } from '../services/login.service'
import { Router, Route, ActivatedRoute, ParamMap } from '@angular/router';
import { ToursService } from '../services/tours.service'
import { PropertyService } from '../services/property.service'
import { ClientService } from '../services/client.service'

import { PropertyListComponent } from '../property-list/property-list.component'

import { Tour } from '../tour'
import { Property } from '../propertySearch'
import { BasicObject } from '../basicObject'
import { ShowingOrderUpdate, ShowingRequestStatus, RequestActions } from '../showing'
import { 
	unixTimestampToDate, 
	isValidDate, 
	formatTourDate, 
	formatShowingDate, 
	formatConfirmationTime, 
	showingRequestStatus, 
	appointmentDateTime,
	appointmentDate,
	dateFromEpochTime,
	appointmentDate1,
	epochTimeFromDate,
	checkForAuthError } from '../utils'

import { ConfirmComponent } from '../confirm/confirm.component'
import { ConfirmInputComponent } from '../confirm-input/confirm-input.component'

import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { BaseComponent } from '../base/base.component'
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';


@Component({
	selector: 'app-tours',
	templateUrl: './tours.component.html',
	styleUrls: ['./tours.component.css']
})
export class ToursComponent extends BaseComponent {

	/**
 	 Existing tours when fetched
	*/
	tours: Tour[] = []

	/**
 	 Should show the tours list dialog or not
	*/
	showToursList = true

	/**
 	 Error if create client or other server request failed
	*/
	error: string = null

	/**
 	 Select all
	*/
	selectAllChecked = false

	/**
 	 A list of properties that have been passed along from an SSO login from MLS
	*/
	mlsList: [string] = null

	/**
 	 Which MLS did the list originate from?
	*/
	mlsName: string = null

	/**
 	 List of standardized request actions
	*/
	requestActions: RequestActions[] = []

	/**
 	 The properties as pulled from the mlsList query parameter
	*/
	trailingProperties: Property[] = []


	/**
 	 Value for when the spinner should be shown
	*/
	showSpinner = false

	/**
 	 Show the done checkmark
	*/
	showDone = false


	constructor(
		protected loginService: LoginService,
		protected router: Router,
		protected route: ActivatedRoute,
		private toursService: ToursService,
		private propertyService: PropertyService,
		private clientService: ClientService,
		private dialog: MatDialog,
		private gaService: GoogleAnalyticsService
		) { 

		super( loginService, router, route )

	}

	ngOnInit(): void {


		// Get any properties passed by query parameter, as via an SSO entrance from an MLS
		this.fetchTrailingProperties()

		// Get all the tours this agent has
		this.fetchToursList()

		// Get the list of request actions available and pass it to view children
		this.fetchRequestActions()

	}


	/**
	 If any mlsId's are present as query parameters, this will indicate we have been fed them
	 by an external MLS source
	*/
	fetchTrailingProperties() {

		// Extract any passed list of mls id's
		let map = this.route.snapshot.queryParams
		this.mlsList = map['mlsList']?.split(',')
		this.mlsName = map['mlsName']

		// Guard for no items present
		if ( this.mlsList == null || this.mlsName == null ) {
			return
		}

		this.propertyService
			.fetchPropertiesForMls( this.mlsList, this.mlsName )
			.subscribe(response => { 
	  
	  	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
					this.error = checkForAuthError( response["errors"], this.router )
	  				return
	  			}  

			    this.trailingProperties = response["data"]["propertiesFromMls"]

		})
	}

	/**
 	 For when the tours-list child wants to update the whole thing.
 	 NOTE: Perhaps we should allow the tours-list component to also do its own refresh. 
	*/	
	onUpdateRequest() {
		this.fetchToursList()
	}

	/**
 	 Fetch all the tours this agent has created
	*/	
	fetchToursList() {
    	this.error = null;
        this.showSpinner = true; // Show the spinner while fetching tours

        this.toursService.fetchTours().subscribe(

          (tours) => {
            // Assume for alpha stage that an error means logged out
            if (tours["errors"] != null) {
              this.error = checkForAuthError(tours["errors"], this.router);
              this.showSpinner = false; // Hide the spinner in case of error
              return;
            }

            this.tours = tours["data"]["tours"];
            // Add in a date control for each showing.
            for (var i in this.tours) {
              let tour = this.tours[i];
              // TODO: dateControl not used anymore?
              tour.showings = tour.showings.map((x) => {
                x.dateControl = new UntypedFormControl("");
                x.dateControl.value = dateFromEpochTime(x.showingTime);
                return x;
              });
              this.tours[i] = tour;
            }
            this.showSpinner = false; // Hide the spinner after data is fetched
          },
          (error) => {
            console.error(error);
            this.showSpinner = false; // Hide the spinner in case of error
          }
        );
      }


	/**
 	 Fetch all the Request Actions available
	*/	
	fetchRequestActions() {

		this.error = null

		this.toursService
			.fetchRequestActions()
			.subscribe(response => { 
		
					// Assume for alpha stage that an error means logged out
					if ( response["errors"] != null ) {
						this.error = checkForAuthError( response["errors"], this.router )
						return
					}  

					this.requestActions = response["data"].requestActions

		})
	}


	/**
 	 Are there properties following the user from an MLS entrance?
	*/
	showTrailingProperties() {
		return this.mlsList != null
	}

	/**
 	 Show the green success check mark
	*/
	showCheckmark(next) {
	    this.showDone = true
	    setTimeout(() => {
	    	this.showDone = false

	    	if ( next != null ) {

	    		// Pass "self" context
	    		next(this)
	    	}

	    }, 3000)
	}


	/**
 	 Create a new tour
	*/
	onAddTour() {
		this.gaService.sendCtaClickEvent({page_name:"tours"},{cta_type: 'label',cta_location:'my_tours', cta_description:'add_tour'})
		let queryParams = this.route.snapshot.queryParams
		if ( this.mlsList != null && this.mlsName != null ) {
			queryParams.mlsList = this.mlsList.join(',')
			queryParams.mlsName = this.mlsName
		}

    	const inputDialogRef = this.dialog.open(ConfirmInputComponent, {
    		data: {
    		    height: '300px',
		       	message: 'Create a Tour',
				currentRoute: 'tours',
				buttonText: {
					ok: 'CREATE',
					cancel: 'CANCEL',
				},
				inputs: [
					{ name: "Tour Name", value: null },
					{ name: "Tour Description", value: null }
				]
			}
    	});

    	let runAfterClosed = (result) => {

    		if (result?.confirmed) {

				this.showSpinner = true

    			// Create the new tour and get its id
    			this.toursService
    				.addTour( result.tour, null, null )
    				.subscribe(response => {

						if ( response["errors"] != null ) {
							this.error = checkForAuthError( response["errors"], this.router )
							// this.gaService.sendErrorDisplayEvent({page_name:'new_tour',message:this.error,type:"form_validation"})
							return
						}

						this.fetchToursList();
						
						this.showSpinner = false;
						this.showCheckmark(null);
    				})
    		}

    	}

    	runAfterClosed.bind(this)

    	inputDialogRef.afterClosed().subscribe(runAfterClosed)

	}


	// Toggle showing full tours list
	onShowExisting() {

		this.showToursList = this.showToursList == true ? false : true
	}


	/**
 	 Submit a request to the listing agent to confirm a showing time.
	*/
	onRequestConfirmation(showing) {

		this.toursService
			.requestShowingAppointment(showing.id, this.requestActions
				.filter( x => { return x.name == "Showing Agent Confirm Request" } )
				.shift()
				.code 
			)
			.subscribe(response => {

	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
					this.error = checkForAuthError( response["errors"], this.router )
	  				return
	  			}


	  			showing.showingConfirmationRequest = [response['data'].scheduler.requestShowingConfirmation]
			})
	}


	/**
 	 Event received when a showing order is dropped

	*/
	onShowingReorder(showingsList: any[], event: CdkDragDrop<any[]>) {

		let moving = {
			id: Number(showingsList[event.previousIndex].id),
			showingOrder: event.currentIndex
		}
		let bumped = {
			id: Number(showingsList[event.currentIndex].id),
			showingOrder: event.previousIndex
		}

		// Since we're not refreshing the tours list, we'll update these manually to match the change
		showingsList[event.previousIndex].showingOrder = bumped.showingOrder
		showingsList[event.currentIndex].showingOrder = moving.showingOrder

		let updates : ShowingOrderUpdate[] = [moving,bumped]

		this.toursService
			.changeShowingOrder(updates)
			.subscribe(response => {


	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
					this.error = checkForAuthError( response["errors"], this.router )
	  				return
	  			}
			})

		moveItemInArray(showingsList, event.previousIndex, event.currentIndex);

	}

	/**
 	 Update the tour date
	*/
	onUpdateTourDate(updateInfo) {

		console.log("updated infor we are getting is",updateInfo)

		let tour = updateInfo.tour
		let newDate = updateInfo.newDate
		let oldDate = updateInfo.oldDate;

		const self = this
		let pendingOrActiveShowings = tour.showings.filter( showing => {
					
			    console.log("pending showing data we are getting is",showing);
				let status = showingRequestStatus(showing)
				console.log("status is",status);
				
				if ( status != ShowingRequestStatus.none ) {
					return true
				}

				return false

			}).map( each => {
				return `${each.property.address.fullAddress} at ${appointmentDateTime(each.showingTime)}`
			})

		let pendingMessage = {
			message: `You have confirmed or pending confirmation requests in this tour. 
			Changing them will require a new request.`,
			title: `Change Tour Date to ${appointmentDate(newDate)}?`
		}

		let noPendingMessage = {
			message: `Do you want to change the tour date from ${appointmentDate1(oldDate)}  to ${appointmentDate1(newDate)} ?`,
			title: `CONFIRM`
		}


		// let message = pendingOrActiveShowings.length > 0 ? pendingMessage : noPendingMessage
		let message = noPendingMessage


		// this.showSpinner = true;
		this.confirmTourDateChange([], message.message, message.title)
			.subscribe( result => {
				this.error = null
				if ( result && result.confirmed == true ) {

					this.toursService
						.updateTourDate(tour.id, newDate)
						.subscribe(response => {

							this.showSpinner = false;

				  			// Capture server error messages
				  			if ( response["errors"] != undefined ) {
								this.error = checkForAuthError( response["errors"], this.router )

								// this.showSpinner = false;
				  				return
				  			}

				  			this.tours = response['data']['updateTourDate']
						})

				// Not the right way to do this refresh
				} else {
					this.showSpinner = false;
					this.fetchToursList()
				}
			})


	}

	/**
 	 Update the time of the viewing for this listing.
	*/
	onUpdateShowingTime(eventData) {
		var showing = eventData.showing
		var event = eventData.event
		var tour = eventData.tour 
		let time = new Date(`${event.value}`);
		// let time = new Date(`${event.value}`).toString().slice(16,24).split(':')

		let showingDateTime = dateFromEpochTime(tour.tourDate)
		showingDateTime.setHours(time.getHours())
		showingDateTime.setMinutes(time.getMinutes())
		showingDateTime.setSeconds(0)

		// showing.showingTime = epochTimeFromDate(showingDateTime);
		showing.showingTime =showingDateTime;
		this.toursService
			.updateShowingTime(showing.id, showing.showingTime)
			.subscribe(response => {

	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
					this.error = checkForAuthError( response["errors"], this.router )
	  				return
	  			}
			})
	}


	/**
 	 Show a dialogue to confirm deletion
	*/
	confirmDelete(list: string[], message: string) {

	    const dialogRef = this.dialog.open(ConfirmComponent, {
			panelClass: 'confirm-popup-dialog',
			data: {
		        message: message,
		        buttonText: {
					ok: 'DELETE',
					cancel: 'CANCEL'
		        },
		        list: list
			}
	    });

	    return dialogRef.afterClosed()

	}

	/**
 	 Confirm a tour date change. With active showing requests, this will need to
 	 generate new ones and re-request for confirmation.
	*/
	confirmTourDateChange(list: string[], message: string, confirmText: string) {

	    const dialogRef = this.dialog.open(ConfirmComponent, {
			panelClass:['confirmList','tourDateCOnfirm'],
			data: {
		        message: message,
		        buttonText: {
					ok: confirmText,
					cancel: 'CANCEL'
		        },
		        list: list
			}
	    });

	    return dialogRef.afterClosed()

	}

	/**
 	 Remove a client from a tour
	*/
	onRemoveClient(eventData) {

		let client = eventData.client
		let tour = eventData.tour

		this.clientService
			.removeClientsFromTour([client.id], tour.id)
			.subscribe(response => {

	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
					this.error = checkForAuthError( response["errors"], this.router )

	  				return
	  			}

	  			// Remove just that one client if the action was successful
			    for ( var i in this.tours ) {

			    	if ( tour.id == this.tours[i].id ) {
			    		this.tours[i].clients = this.tours[i].clients.filter( (x) => { return x.id != client.id })
			    	}
			    }

			})

	}


	/**
 	 Delete a single showing from a tour
	*/
	onDeleteShowing(eventData) {

		let showing = eventData.showing
		let tour = eventData.tour

		this.confirmDelete([], "Are you sure you want to delete this viewing?")
			.subscribe((result) => {

				this.error = null
				if ( result.confirmed ) {
					this.toursService
						.deleteShowing(showing.id, tour.id)
						.subscribe(response => {

				  			// Capture server error messages
				  			if ( response["errors"] != undefined ) {
								this.error = checkForAuthError( response["errors"], this.router )

				  				return
				  			}

				    		this.toursService
								.fetchTours()
								.subscribe(tours => { 
						  
								    // Assume for alpha stage that an error means logged out
								    if ( tours["errors"] != null ) {
										this.error = checkForAuthError( tours["errors"], this.router )

								    }  

								    this.tours = tours["data"]["tours"]
								    this.tours.forEach(each => { each.checked = false })

							})


						})
				}

			})

	}

	/**
 	 Remove the tour(s) and showings entirely
	*/
	onDeleteTours(event) {

		let list = this.tours
			.filter( x => { return x.checked })
			.map( each => { return `${each.name}` })

		if ( list.length < 1 ) {
			this.error = "Please make a selection first"
			return
		}

		const confirmationMessage =
        list.length === 1
            ? `Are you sure you want to delete this tour?`
            : `Are you sure you want to delete these tours?`;

		this.confirmDelete(list, confirmationMessage)
			.subscribe((confirmed: boolean) => {

				this.error = null

				if (confirmed) {

					let idList = this.tours
						.filter( x => { return x.checked })
						.map( x => { return x.id })

					this.toursService
						.deleteTours(idList)
							.subscribe(tours => { 
					  
					  			// Capture server error messages
					  			if ( tours["errors"] != undefined ) {
									this.error = checkForAuthError( tours["errors"], this.router )
					  				return
					  			}

					    		this.toursService
									.fetchTours()
									.subscribe(tours => { 
							  
									    // Assume for alpha stage that an error means logged out
									    if ( tours["errors"] != null ) {
											this.error = checkForAuthError( tours["errors"], this.router )

										    return
									    }  

									    this.tours = tours["data"]["tours"]
									    this.tours.forEach(each => { each.checked = false })

								})

						})
				}
	    })


	}

	onDeleteTour(event) {

		let list = event
			.map( each => { return `${each.name}` })

		if ( list.length < 1 ) {
			this.error = "Please make a selection first"
			return
		}

		const confirmationMessage =
        list.length === 1
            ? `Are you sure you want to delete this tour?`
            : `Are you sure you want to delete these tours?`;

		this.confirmDelete(list, confirmationMessage)
			.subscribe((confirmed: boolean) => {

				this.error = null

				if (confirmed) {

					let idList = event
						.map( x => { return x.id })

					this.toursService
						.deleteTours(idList)
							.subscribe(tours => { 
					  
					  			// Capture server error messages
					  			if ( tours["errors"] != undefined ) {
									this.error = checkForAuthError( tours["errors"], this.router )
					  				return
					  			}

					    		this.toursService
									.fetchTours()
									.subscribe(tours => { 
							  
									    // Assume for alpha stage that an error means logged out
									    if ( tours["errors"] != null ) {
											this.error = checkForAuthError( tours["errors"], this.router )

										    return
									    }  

									    this.tours = tours["data"]["tours"]
									    this.tours.forEach(each => { each.checked = false })

								})

						})
				}
	    })


	}

}
