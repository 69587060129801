
<div class="row">
  <div class="col-md-12 topBanner">
    <div style="position: absolute;z-index: 1;width:100%">
    <div class="home-container">
      <div class="bannerText"><img src="assets/banner-mapIcon.png" alt="Icon" width="" tabindex="0" class="HomeMapIcon"><div>Discover your dream pad with <span>LivePad</span></div></div>
    </div>
  </div>
    <img src="assets/home-bannerImg.png" alt="Home banner" width="" tabindex="0" class="bannerImage">
  </div>
</div>
<div class="home-container">
    <div class="row mt-3">
      <div class="col-md-6 welcome-text">
        <div class="border-left"></div>
        <h1 tabindex="0">Find your next pad <br>with LivePad</h1>
        <p>At LivePad we know that you want to be an irreplaceable resource to your clients by providing true value.</p>
        <p>It’s difficult to spend quality time with your clients when it matters most and we want your clients to see all the benefits you bring to the table as their agent. With more than 23 years of industry experience we created a solution for this common problem. </p>
      </div>
      <div class="col-md-6 office-location">
        <div class="root-map"></div>
        <div class="mobile-look">
            <img src="assets/iPhone11ProStartTour.png" alt="iPhoneStartTour view" width="100%" tabindex="0">
            <!-- <div class="office-location-details">
                <img src="assets/office-location.png" alt="Image" width="100%" tabindex="0">
                <div class="office-location-details-text">
                    <div class="location-tab">
                      <div class="location-count"><input type="checkbox" name="checkbox" class="location-tab-checkbox" id="tab-checkbox"/> <span>1</span></div>
                      <div class="address-text"><p>6963 Espana<br> Centennaial, CO 90016</p><p style="margin-bottom: 5px;">Price: $3749000</p><p><a>View Details</a> <span>Preview</span></p></div>
                    </div>
                    <div class="location-tab">
                      <div class="location-count"><input type="checkbox" name="checkbox" class="location-tab-checkbox" id="tab-checkbox"/> <span>2</span></div>
                      <div class="address-text"><p>6963 Espana<br> Centennaial, CO 90016</p><p style="margin-bottom: 5px;">Price: $3749000</p><p><a>View Details</a> <span>Preview</span></p></div>
                    </div>
                </div>
                <div class="start-tour">Start your tour</div>
                <div class="reorder-btn-red">Reorder</div><div class="reorder-btn-white">Reorder</div>
            </div> -->
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <h1 class="welcome-title">The LivePad Off-Grid version comes with everything you need to take full advantage of LivePad</h1>
        <div class="row mt-3 mx-4 tabs-included">
            <div class="col-md-3"><img src="assets/icon-park-solid_protect.png" alt="icon-park-solid_protect" width="" tabindex="0"><p>Protective Case</p></div>
            <div class="col-md-3"><img src="assets/ic_baseline-tablet.png" alt="ic_baseline-tablet" width="" tabindex="0"><p>Pre-Loaded Samsung 
                <br>Galaxy A8 Tablet </p></div>
            <div class="col-md-3"><img src="assets/solar_pen-bold.png" alt="solar_pen-bold" width="" tabindex="0"><p>Stylus Pen</p></div>
            <div class="col-md-3"><img src="assets/mdi_cloth.png" alt="mdi_cloth" width="" tabindex="0"><p>Microfiber <br>Cleaning Cloth</p></div>
        </div>
      </div>
    </div>
    <div class="row mt-3 bottom-content">
      <div class="col-md-8 our-deal-container">
        <div class="our-deal">
            <div class="border-left"></div>
            <h1>Everything you need, in one convenient place</h1>
            <p>Presently, LivePad subscriptions are available exclusively through local MLS organizations. Please inquire with your local MLS to subscribe.</p>
        </div>
      </div>
      <div class="col-md-4 iphone-img">
        <img src="assets/iPhone11Pro.png" alt="iPhone11Pro view" width="" tabindex="0">
      </div>
    </div>
</div>
<div class="row mt-3">
  <div class="col-md-12 bg-image">
    <img src="assets/mobile-keybanner.png" alt="mobile-keybanner view" width="" tabindex="0" class="mobile-bottom-banner">
    <div class="client-relation-text">
        <h2>Improve Client Relationships with Modern Technology</h2>
        <p>The LivePad Mobile version empowers you with LivePad services by allowing you to send tours to your client’s mobile devices. </p>
    </div>
  </div>
</div>